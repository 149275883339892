<template>
  <div class="custom-form custom-form--contact custom-crm-form">
    <div class="custom-form__header">
      <slot name="content-introduction" />
    </div>
    <form novalidate @submit.prevent="submit">
      <div class="columns">
        <div class="column">
          <div role="group" aria-labelledby="legend-kids" class="field field--radio">
            <div id="legend-kids" class="legend" />
            <ul class="field--radio">
              <field-radio v-model="form.gender" :field="fieldGender" />
              <p v-if="hasErrorGender" id="gender-error" class="field--error">
                <span class="field-help">
                  {{ genderError }}
                </span>
              </p>
            </ul>
          </div>
        </div>
        <div class="column" />

        <div v-if="!fieldFirstName.isDisabled" class="column">
          <div class="fieldset">
            <field-input
              ref="firstName"
              v-model="form.firstName"
              :v="$v.form.firstName"
              :mandatory-value="fieldFirstNameMandatory"
              :field="fieldFirstName"
            />
          </div>
        </div>

        <div v-if="!fieldLastName.isDisabled" class="column">
          <div class="fieldset">
            <field-input
              ref="lastName"
              v-model="form.lastName"
              :v="$v.form.lastName"
              :mandatory-value="fieldLastNameMandatory"
              :field="fieldLastName"
            />
          </div>
        </div>

        <div v-if="!fieldEmail.isDisabled" class="column">
          <div class="fieldset">
            <field-input v-model="form.email" :v="$v.form.email" :field="fieldEmail" />
          </div>
        </div>

        <div v-if="!fieldEmail.isDisabled" class="column">
          <div class="fieldset">
            <field-input
              v-model="form.confirmEmail"
              :v="$v.form.confirmEmail"
              :field="fieldConfirmEmail"
            />
          </div>
        </div>

        <div class="column countryTerms">
          <div class="fieldset">
            <field-input
              v-model="form.countryTerms"
              :v="$v.form.countryTerms"
              :field="fieldCountryTerms"
            />
          </div>
        </div>
        <date-birth
          :mandatory-value="fieldBirthdateMandatory"
          :field-birth="fieldBirth"
          :field-minage-length="fieldMinageLength.label"
          :start-validate="validateDate"
          @birth="setBirthData"
          @birthIsValid="setBirthValidation"
        />

        <div class="column country-clear">
          <div v-if="fieldCountryCodes.length > 0" class="fieldset selected">
            <field-select v-model="form.country" :field="fieldCountryCodes" />
          </div>
        </div>
      </div>

      <div
        v-for="(item, index) in fieldCheckboxes"
        :key="index"
        class="custom-form__checkbox-group"
        :class="form.country"
      >
        <field-checkbox
          v-if="item.required"
          v-model="form.gdpr[`checkbox-${item.gdprId}`]"
          :class="item.ItemName"
          :value="form.gdpr[`checkbox-${item.gdprId}`]"
          :v="$v.form.gdpr[`checkbox-${item.gdprId}`]"
          :field="item"
        />
        <field-checkbox
          v-else
          v-model="form.gdpr[`checkbox-${item.gdprId}`]"
          :class="item.ItemName"
          :value="form.gdpr[`checkbox-${item.gdprId}`]"
          :field="item"
        />
      </div>

      <captcha
        v-if="fieldCaptcha.isVisible"
        ref="captcha"
        :captcha-id="fieldCaptcha.id"
        :captcha-sitekey="fieldCaptcha.siteKey"
        :explicitly-render="true"
        @captchaError="(value) => getCaptchaError(value)"
      >
        <p v-if="hasErrorCapcha" :id="fieldCaptcha.idError" class="field-help">
          {{ fieldCaptcha.error }}
        </p>
      </captcha>

      <div class="custom-form__actions">
        <div>
          <template v-if="!serverResponse && serverError === null">
            <button
              v-tag:useractionevent="{
                label: 'newsletter subscription',
                category: 'newsletter subscription form',
                action: 'validate::form',
              }"
               type="submit"
               :class="[ctaClass, { 'disabled': $v.form.$invalid || !this.isBirthValid }]"
               
            >
             <span class="btn__wrapper"> {{ template.ctaSubmit }}</span>
            </button>
          </template>

          <template v-else>
            <a :href="ctaLink" :class="ctaClass">
             <span class="btn__wrapper"> {{ template.ctaSuccess }}</span>
            </a>
          </template>

          <div role="alert">
            <p
              v-if="serverResponse !== null"
              class="custom-form__response"
              :class="{ error: !serverResponse }"
            >
              {{ serverResponse ? template.succeed : template.failure.default }}
            </p>
            <p v-if="serverError !== null" class="custom-form__response error">
              {{ template.error }}
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { email, required, sameAs, maxLength } from 'vuelidate/lib/validators';
import { tag } from '@Foundation/analyticsHandler';
import { validationMixin } from 'vuelidate';
import { postData } from '@Foundation/services';
import FieldCheckbox from '@Feature/CustomForms/fields/FieldCheckbox/code/Scripts/components/field-checkbox.vue';
import FieldInput from '@Feature/CustomForms/fields/FieldInput/code/Scripts/components/field-input.vue';
import FieldRadio from '@Feature/CustomForms/fields/FieldRadio/code/Scripts/components/field-radio.vue';
import FieldSelect from '@Feature/CustomForms/fields/FieldSelect/code/Scripts/components/field-select.vue';
import Captcha from '@Feature/Captcha/code/Scripts/components/captcha.vue';
import dateBirth from '@Feature/CustomForms/dateOfBirth/code/Scripts/components/date-birth.vue';

export default {
  name: 'CustomCrmNewsletter',

  components: {
    FieldCheckbox,
    FieldInput,
    FieldRadio,
    FieldSelect,
    dateBirth,
    Captcha,
  },

  directives: { tag },

  mixins: [validationMixin],

  props: {
    fieldEmail: { type: Object, required: true, default: undefined },
    fieldEmailLength: { type: Object, required: false, default: undefined },
    fieldFirstnameminLength: {
      type: Object,
      required: false,
      default: undefined,
    },
    fieldFirstnamemaxLength: {
      type: Object,
      required: false,
      default: undefined,
    },
    fieldLastnameminLength: {
      type: Object,
      required: false,
      default: undefined,
    },
    fieldLastnamemaxLength: {
      type: Object,
      required: false,
      default: undefined,
    },
    fieldConfirmEmail: { type: Object, required: true, default: undefined },
    fieldFirstName: { type: Object, required: true, default: undefined },
    fieldCountryTerms: { type: Object, required: true, default: undefined },
    fieldLastName: { type: Object, required: true, default: undefined },
    fieldGender: { type: Object, required: true, default: undefined },
    fieldCheckboxes: { type: Array, required: true, default: undefined },
    fieldBirth: { type: Object, required: true, default: undefined },
    fieldMinageLength: { type: Object, required: false, default: undefined },
    fieldCaptcha: { type: Object, required: false, default: undefined },
    template: { type: Object, required: true, default: undefined },
    apiUrl: { type: String, required: true, default: undefined },
    genderError: { type: String, required: true, default: undefined },
    fieldCountryCodes: { type: Array, required: false },
    ctaClass: { type: String, default: 'cta cta--light' },
  },

  data() {
    return {
      form: {
        email: '',
        emailLength: '',
        confirmEmail: '',
        firstName: '',
        gender: '',
        lastName: '',
        country: '',
        gdpr: {},
        birth: {
          day: '',
          month: '',
          year: '',
        },
      },
      serverResponse: null,
      serverError: null,
      hasErrorCapcha: false,
      updateFields: false,
      ctaLink: null,
      isBirthValid: true,
      validateDate: true,
      countrySelected: '',
      salutationPicked: '',
      hasErrorGender: false,
    };
  },

  /**
   * Vuelidate config
   * @see {@link https://github.com/monterail/vuelidate|GitHub}
   */
  validations() {
    const validationSettings = {
      form: {
        email: {
          required,
          maxLength: maxLength(this.fieldEmailLength.label),
          email,
        },
        confirmEmail: {
          required,
          maxLength: maxLength(this.fieldEmailLength.label),
          sameAsPassword: sameAs('email'),
        },
        firstName: {},
        lastName: {},
        birth: { day: {}, month: {}, year: {} },
        gdpr: {},
      },
    };

    for (let index = 0; index < this.fieldCheckboxes.length; index++) {
      if (this.fieldCheckboxes[index].required) {
        validationSettings.form.gdpr[`checkbox-${this.fieldCheckboxes[index].gdprId}`] = {
          required,
          sameAs: sameAs(() => true),
        };
      }
    }

    this.fieldLastName.isDisabled ? (validationSettings.form.lastName = {}) : false;
    this.fieldFirstName.isDisabled ? (validationSettings.form.firstName = {}) : false;

    return validationSettings;
  },

  watch: {
    country(value) {
      this.getCountryData(value);
    },
  },

  created() {
    this.setCheckboxesToVueData();
    window.onCaptchaAPIReady = this.onCaptchaAPIReady;
  },
  mounted() {
    setTimeout(() => {
      this.PredefinedValues();
      this.countrySelection();
    }, 500);
  },

  /* eslint-disable no-undef */
  methods: {
    setCheckboxesToVueData() {
      this.fieldCheckboxes.forEach((item) => {
        this.$set(this.form.gdpr, `checkbox-${item.gdprId}`, item.value);
      });
    },
    setBirthValidation(payload) {
      this.isBirthValid = payload;
    },
    setBirthData(payload) {
      this.form.birth[payload.type] = payload.value;
    },
    getCaptchaError(val) {
      this.hasErrorCapcha = val;
    },
    isGenderEmpty() {
      if (this.form.gender === '') {
        this.hasErrorGender = true;
      } else {
        this.hasErrorGender = false;
      }
    },

    /** check if captcha required for validation before sending a request
     * @param {function} callback - function to call after validation
     */
    validateCapcha(callback) {
      if (this.fieldCaptcha.isVisible) {
        this.$refs.captcha.checkCaptcha();

        this.$nextTick(() => {
          /* istanbul ignore else */
          if (!this.hasErrorCapcha) {
            callback();
          }
        });
      } else {
        callback();
      }
    },
    PredefinedValues() {
      Object.keys(this.$props).forEach((item) => {
        switch (item) {
          case 'fieldGender':
            this.form.gender = this.$props[item].value || '';
            break;
          case 'fieldFirstName':
            this.form.firstName = this.$props[item].value || '';
            break;
          case 'fieldLastName':
            this.form.lastName = this.$props[item].value || '';
            break;
          case 'fieldBirth':
            this.form.birth.day = this.$props[item].day.value || '';
            this.form.birth.month = this.$props[item].month.value || '';
            this.form.birth.year = this.$props[item].year.value || '';
            break;

          default:
            break;
        }
      });
    },
    countrySelection() {
      var countryRef = this;
      if (document.getElementById('country')) {
        document.getElementById('country').selectedIndex = 0;
        this.form.country = document.getElementById('country').value;
        this.form.countryTerms = document.getElementById(this.form.country).innerText;

        document.getElementById('country').onchange = function () {
          var selectedCountry = this.value;
          var countryTerms = document.getElementById(selectedCountry).innerText;
          countryRef.form.countryTerms = countryTerms;
          var divsToHide = document.querySelectorAll('.countryTermsAll');
          for (var i = 0; i < divsToHide.length; i++) {
            divsToHide[i].style.display = 'none';
          }
          document.getElementById(selectedCountry).style.display = 'block';
        };
      }
    },

    sendRequest() {
      const gdpr = this.fieldCheckboxes.map((item, index) => {
        return {
          gdrpSettingItemId: this.fieldCheckboxes[index].gdprId,
          isSelected: this.form.gdpr[`checkbox-${item.gdprId}`],
        };
      });
      postData(`${window.location.origin}/${this.apiUrl}`, {
        ...this.form,
        gdpr,
      })
        .then((response) => {
          this.serverResponse = response.status;
          if (this.serverResponse && !!response.url) {
            this.ctaLink = response.url;
          }
        })
        .catch(() => {
          this.serverError = true;
        });
    },

    /**
     * @todo
     * @returns {undefined}
     */
    submit() {
      // this.isGenderEmpty();
      this.validateDate = false;
      //this.$v.form.$touch();
      if (!this.hasErrorGender) {
        if (this.$v.form.$pending || this.$v.form.$error) {
          this.validateDate = true;
          return;
        } else {
          this.validateDate = true;
          this.validateCapcha(this.sendRequest);
        }
      }
    },
  },
};
</script>

<style lang="scss" src="./custom-crm-newsletter.scss"></style>
